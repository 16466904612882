import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
          <a
            href="#main-content"
            className="element-invisible element-focusable"
          >
            Informationen zum Thema Durchfall
          </a>
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/rechtliche-hinweise"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
                IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-sidebar">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/teaser_03.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 15px 20px 15px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4
                        style={{
                          "font-family": "Georgia",
                          "font-style": "italic",
                          "font-weight": "normal",
                          "font-size": "20px",
                          "line-height": "26px",
                          margin: "20px 0 10px 0"
                        }}
                      >
                        Sie erreichen uns kostenlos
                        <br />
                        00800 260 260 00
                      </h4>
                      <p>
                        <span
                          style={{
                            "font-size": "13px",
                            color: "#767575"
                          }}
                        >
                          mo-fr: 09:00 - 16:00 Uhr
                        </span>
                      </p>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Nutzungsbedingungen{" "}
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div
                          className="field-item even"
                          property="content:encoded"
                        >
                          <h3>Anwendungsbereich</h3>
                          <p>
                            Diese Website gehört der Johnson &amp; Johnson GmbH
                            (im Folgenden Betreiber). Sie richtet sich an
                            Besucher in Österreich. Ihre Benutzung unterliegt
                            den folgenden Nutzungsbedingungen. Wenn Sie Services
                            dieser Websites in Anspruch nehmen, gelten daher
                            diese Nutzungsbedingungen.&nbsp;
                          </p>
                          <p>
                            Beachten Sie bitte, dass wir unsere
                            Nutzungsbedingungen unseren Services, Anforderungen
                            und mit Rücksicht auf Gesetzesänderungen von Zeit zu
                            Zeit anpassen. Bitte schauen Sie immer wieder auf
                            diese Seite, um von solchen Änderungen zu erfahren.
                            Ein ausdrücklicher Hinweis auf die Änderung der
                            Nutzungsbedingungen erfolgt nicht. Daher sollten Sie
                            sich bei jeder Nutzung von den jeweils aktuellen
                            Nutzungsbedingungen überzeugen. Der Stand ist
                            datumsmäßig am Ende dieser Nutzungsbedingungen
                            benannt.
                          </p>
                          <h3>Aktualität</h3>
                          <p>
                            Der Betreiber dieser Website wird in angemessener
                            Weise Anstrengungen unternehmen, damit diese Website
                            aktuelle und genaue Informationen enthält. Er gibt
                            aber keine Zusicherungen, Gewährleistungen oder
                            Zusagen hinsichtlich der Richtigkeit, Gültigkeit
                            oder Vollständigkeit der bereitgestellten –
                            unverbindlichen – Informationen. Der Betreiber
                            haftet daher nicht für Schäden, die sich daraus
                            ergeben, dass Sie sich auf Informationen auf dieser
                            Website verlassen haben.
                          </p>
                          <h3>Unsere Verantwortung</h3>
                          <ul>
                            <li>
                              Diese Website kann auch Links oder Verweise auf
                              andere Websites enthalten, aber die Betreiber
                              dieser Website übernehmen keine Verantwortung für
                              den Inhalt solcher Websites und keine Haftung für
                              Schäden oder Verletzungen, die aus der Nutzung –
                              gleich welcher Art – solcher Inhalte entstehen.
                              Links zu anderen Websites werden den Nutzern
                              dieser Website lediglich als Annehmlichkeit zur
                              Verfügung gestellt.
                            </li>
                            <li>
                              Der Betreiber ist berechtigt, alle in
                              Kommunikation ihm gegenüber offengelegten
                              Informationen, einschließlich aller darin
                              enthaltenen Ideen, Erfindungen, Konzepte, Methoden
                              oder enthaltenes Know-how, für jeden Zweck zu
                              verwenden, beispielsweise für die Bekanntgabe an
                              Dritte und/oder die Entwicklung, Herstellung
                              und/oder Vermarktung von Produkten oder
                              Dienstleistungen, sofern nicht etwas anderes – wie
                              eine Vertraulichkeit – vereinbart worden oder
                              gesetzlich vorgeschrieben ist.
                            </li>
                            <li>
                              Der Betreiber haftet unbeschränkt für Vorsatz und
                              grobe Fahrlässigkeit dem Grunde nach. Bei leichter
                              Fahrlässigkeit kommt eine Haftung nur in Betracht,
                              wenn wesentliche, vertragliche Pflichten
                              (Kardinalpflichten) verletzt werden. Im Übrigen
                              ist die Haftung – soweit zulässig – auf den
                              vertragstypischen Schaden betragsmäßig begrenzt.
                              Eine Haftung für Folgeschäden, namentlich für
                              entgangenen Gewinn oder immaterielle Schäden, ist
                              – soweit zulässig – ausgeschlossen. Für Ihre
                              Datenverluste oder sonstige Schäden an Ihrer Hard-
                              oder Software ist der Betreiber nicht
                              verantwortlich. Der Betreiber haftet allerdings
                              unbeschränkt für Schäden aus der Verletzung des
                              Lebens, des Körpers oder der Gesundheit. Die
                              vorgenannten Haftungsbeschränkungen gelten
                              zugunsten der Beschäftigten, der gesetzlichen
                              Vertreter und der Erfüllungsgehilfen des
                              Betreibers und seiner Dienstleister entsprechend.
                            </li>
                            <li>
                              Der Betreiber tritt nicht für Schäden ein, die
                              sich aus dem Zugriff oder fehlenden Zugriff auf
                              diese Website ergeben.
                            </li>
                          </ul>
                          <h3>Ihre Verantwortung: Verbindliche Netiquette</h3>
                          <ul>
                            <li>
                              Sofern Sie die Services des Betreibers in Anspruch
                              nehmen, sind wahrheitsgemäße und vollständige
                              Angaben zu Ihrer Person – insbesondere Vorname,
                              Name, Adresse, Telefonnummer sowie E-Mail-Adresse
                              – unabdingbare Voraussetzung. Diese Website
                              differenziert, welche einzelnen Angaben von Ihnen
                              für die jeweiligen Services verbindlich oder
                              unverbindlich sind. Bitte aktualisieren Sie Ihre
                              persönlichen Angaben, wenn sie sich ändern.&nbsp;
                            </li>
                            <li>
                              Der Absender jeglicher Kommunikation an diese
                              Website oder an den Betreiber ist für den Inhalt
                              sowie die darin enthaltenen Informationen,
                              einschließlich deren Richtigkeit und Genauigkeit,
                              verantwortlich.
                            </li>
                            <li>
                              Es ist untersagt, Material auf die Website zu
                              übertragen, das schadenstiftende Inhalte
                              (insbesondere Malicious Code) und sonstige
                              Programme enthält, welche die Funktionsfähigkeit
                              von Hard- und/oder Software gefährden oder stören
                              können. Sonstige Handlungen, die die System- oder
                              Netzwerksicherheit verletzen oder dies
                              beabsichtigen, wie beispielsweise das Verschaffen
                              eines unautorisierten Zugangs durch das
                              Einschleusen eines Malicious Code oder die
                              Versendung von Spam, sind nicht erlaubt.
                            </li>
                            <li>
                              Der Nutzer hat für die Nachteile einzustehen, die
                              durch seine missbräuchliche oder rechtswidrige
                              Verwendung der Website entstehen.
                            </li>
                          </ul>
                          <h3>Kindern / Jugendliche</h3>
                          <p>
                            Wenn Sie noch nicht volljährig sind, nutzen Sie
                            diese Website nur mit Zustimmung Ihrer
                            Erziehungsberechtigten. Eine Registrierung oder
                            Eingaben und Abrufe kommt also nur in Betracht, wenn
                            Sie mindestens das 18 Jahre Lebensjahr vollendet
                            haben und voll geschäftsfähig sind.
                          </p>
                          <h3>
                            Ihre Nutzungsrechte / Unsere Urheber- / Markenrechte
                            /
                          </h3>
                          <ul>
                            <li>
                              Die Marken, Markennamen und Firmen dieser Website
                              wie ihre Bilder, Texte und/oder grafischen
                              Gestaltungen sind in Österreich sowie
                              international marken-, namenschutz- und/oder
                              urheberrechtlich geschützt. Sie dürfen in keiner
                              Weise ohne die vorherige schriftliche Zustimmung
                              des Betreiber dieser Website genutzt oder sonst
                              verwendet werden außer zur Identifizierung der
                              Produkte oder Serviceleistungen des Unternehmens
                              und im Übrigen ausschließlich für den privaten,
                              nicht gewerblichen Gebrauch.
                            </li>
                            <li>
                              Markenbezeichnungen und Urheberrechtshinweise
                              dürfen in keinem Fall verändert oder beseitigt
                              werden. Die kommerzielle Nutzung, insbesondere das
                              Einstellen kommerzieller Werbung, ist untersagt.
                            </li>
                            <li>
                              Im Übrigen wird durch die Benutzung der Website
                              weder ausdrücklich, konkludent noch auf sonstige
                              Weise eine Lizenz oder ein Recht zum Gebrauch
                              geistiger Eigentumsrechte gewährt, deren
                              Gegenstand auf dieser Website enthalten ist.
                              Jegliche widerrechtliche Verwendung ist
                              ausdrücklich untersagt.
                            </li>
                            <li>
                              Aus den Nutzungsbedingungen folgende Rechte und
                              Pflichten sind auch nicht teilweise ohne die
                              vorherige Zustimmung des Betreibers durch Sie auf
                              Dritte übertragbar.
                            </li>
                            <li>
                              Der Betreiber steht nicht dafür ein, dass das auf
                              der Website angezeigte Material nicht in Rechte
                              Dritter eingreift.
                            </li>
                          </ul>
                          <h3>Datenschutzverweis</h3>
                          <p>
                            Alle personenbezogenen Daten, die in elektronischer
                            Kommunikation über diese Website übermittelt werden,
                            unterliegen dem Datenschutz.{" "}
                            <a href="/datenschutz">
                              Hier{" "}
                            </a>
                            finden Sie ausführliche Informationen über die
                            Verwendung und Speicherung Ihrer Daten durch diese
                            Website.
                          </p>
                          <h3>Beendigung</h3>
                          <p>
                            Der Betreiber behält sich das Recht vor, den Inhalt
                            dieser Website jederzeit aus freiem Grund ohne
                            Ankündigung zu streichen, zu ändern oder zu
                            ergänzen, sofern nicht ausdrücklich Services auf
                            anderer Grundlage erbracht werden. Ein
                            Rechtsanspruch besteht nur dann, wenn dieser
                            ausdrücklich begründet oder kraft Gesetzes gegeben
                            ist.
                          </p>
                          <h3>Anwendbares Recht</h3>
                          <p>
                            Diese Nutzungsbedingungen unterliegen – soweit
                            zulässig – dem deutschen Recht.
                          </p>
                          <h3>Unwirksamkeit</h3>
                          <p>
                            Sollten einzelne Bestimmungen dieser
                            Nutzungsbedingungen unwirksam sein oder werden oder
                            den gesetzlichen Regelungen widersprechen, so wird
                            hierdurch die Wirksamkeit der übrigen
                            Nutzungsbedingungen nicht berührt. Die unwirksame
                            Bestimmung wird von den Parteien einvernehmlich
                            durch eine solche Bestimmung ersetzt, welche dem
                            wirtschaftlichen Zweck der unwirksamen Bestimmung in
                            rechtswirksamer Weise am nächsten kommt. Die
                            vorstehende Regelung gilt entsprechend bei
                            Regelungslücken.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf active-trail">
                    <a
                      href="/rechtliche-hinweise/"
                      title
                      className="active-trail active"
                    >
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                   <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2023. Diese Website wird von der Johnson &amp; Johnson GmbH veröffentlicht, die allein für deren Inhalte verantwortlich ist. Sie ist für Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 10. Mai 2023, AT-IM-2300001
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
